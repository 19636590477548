option,
select {
  font-size: large;
  text-align: center;
  border-color: gray;
}

.button {
  padding: 3px;
}

.ulFilter {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  padding: 0;
}
