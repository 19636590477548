.ul {
  list-style: none;
}

.li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid gray;
}

.h1 {
  margin: 15px;
}

.info {
  display: flex;
  flex-direction: column;
  margin: 20px;
}
